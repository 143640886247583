import Slider from "../components/Slider";
import {useTranslation, Trans} from "react-i18next";
const Home = () => {
    const { t } = useTranslation();

    return (
        <div id="content-wrapper">
                <Slider />
                <section id="titlebar">
                    <div className="container">

                        <div className="eight columns">
                            <h2>{t('ABOUT.TITLE')}</h2>
                        </div>

                    </div>

                    <br />

                        <div className="container">

                            <div className="sixteen columns">

                                <div className="accordion">

                                    <div>
                                        <Trans i18nKey="ABOUT.DESC">
                                            <p>
                                                ООО "Торговая Компания «ЕкспоСталь" работает на рынке Украины с 2014 года. Наша компания специализируется на поставках труб стальных, листового и сортового металлопроката из углеродистых и нержавеющих марок сталей.
                                            </p>
                                            <p>
                                                За период работы нами налажено плодотворное сотрудничество с ведущими заводами-изготовителями Украины, стран Европейского союза и СНГ. География наших партнеров охватывает всю Евразию. Поставки металлопроката осуществляются на предприятия строительного, нефтяного, химического, металлургического и горнодобывающего комплексов Украины.
                                            </p>
                                            <p>
                                                Особое внимание наша компания уделяет качеству поставляемой продукции. Весь персонал имеет огромный опыт работы в области продаж и поставок металлопроката.
                                            </p>
                                            <p>
                                                Сегодня наша компания – это сплав опыта и профессионализма. Благодаря чему, мы зарекомендовали себя на рынке Украины и Европейского союза, как благонадежный и ответственный партнер.
                                            </p>
                                            <p>
                                                Мы всегда заботимся о качественном выполнении поставки. В работе с нашими Клиентами мы в первую очередь учитываем их индивидуальные особенности и пожелания. Ведь качество поставляемой продукции, лучшая цена и своевременность поставки – это залог успешности наших Клиентов.
                                            </p>
                                            <p>
                                                Одним из важных факторов долгосрочных, эффективных и взаимовыгодных партнерских отношений является предоставление сопутствующих услуг для обеспечения всех потребностей Клиента. Поэтому, кроме обеспечение металлопрокатом, наша компания оказывает целый спектр услуг по механической обработке, погрузке и доставке.
                                            </p>
                                            <p>
                                                Наш многолетний успешный опыт, гибкая ценовая политика и ориентирование на высокий результат, позволяют установить долгосрочные и эффективные отношения со всеми Клиентами компании.
                                            </p>
                                            <br />
                                        </Trans>
                                    </div>
                                    <a className="pleased" href="/contacts.html">
                                        <Trans i18nKey="ABOUT.TOOLTIP">
                                            СПАСИБО ЗА ВАШ ИНТЕРЕС К НАМ!<br />
                                            БУДЕМ РАДЫ НАШЕМУ СОТРУДНИЧЕСТВУ!
                                        </Trans>
                                    </a>
                                </div>
                            </div>

                        </div>
                        <br /><br />
                </section>
</div>
    );
}

export default Home;