import Slider from "../components/Slider";
import {useTranslation, Trans} from "react-i18next";
const Services = () => {
    const { t } = useTranslation();
    return (
        <div id="content-wrapper">
                <Slider />
                <section id="titlebar">
                    <div className="container">

                        <div className="eight">
                            <h2>{t('SERVICES.TITLE')}</h2>
                        </div>

                    </div>
                </section>
                <div className="container certificate mb-35">
                    <div className="column">
                        <h3 className="headline"></h3>
                        <span className="line mb-35"></span>
                        <div className="clearfix"></div>
                        <h3>{t('SERVICES.TAB_1.TITLE')}</h3>
                        <br />
                        <Trans i18nKey={'SERVICES.TAB_1.DESC'}>
                            <p>
                                Вся металлопродукция, приобретенная в нашей компании, может быть доставлена на склад или строительный объект Заказчика по всей территории Украины и стран СНГ.
                            </p>
                            <p>
                                Доставка осуществляется различными автомобилями, тип которого определяется с учётом типа, веса и габаритных размеров всех видов металлопроката, присутствующих в Вашем заказе.
                            </p>
                            <p>
                                Загрузка транспортного средства производится не превышая норм его грузоподъемности и вместимости по габаритам.
                            </p>
                        </Trans>
                    </div>
                </div>

                <div className="container certificate mb-35">

                    <div className="column">
                        <h3 className="headline"></h3>
                        <span className="line mb-35"></span>
                        <div className="clearfix"></div>
                        <h3>{t('SERVICES.TAB_2.TITLE')}</h3>
                        <br />
                        <Trans i18nKey={'SERVICES.TAB_2.DESC'}>
                            <p>
                                Вся металлопродукция, приобретенная в нашей компании, по желанию Заказчика, может быть порезана в необходимый размер
                            </p>
                            <p>
                                Наше предприятия располагает необходимым оборудованием для резки металлопроката и осуществляет резку листа, круга, квадрата, шестигранника, уголка, швеллера, трубы.
                            </p>
                            <p>
                                Весь этот комплекс услуг по резке металла в размер оказываются нашей компанией.
                            </p>
                        </Trans>
                    </div>
                </div>
            </div>
    );
}

export default Services;