import Slider from "../components/Slider";
import {useTranslation} from "react-i18next";
const Contacts = () => {
    const { t } = useTranslation();
    return (
        <div id="content-wrapper">
            <Slider />
            <section id="titlebar">
                <div className="container">
                    <div className="eight">
                        <h2>{t('CONTACTS.TITLE')}</h2>
                    </div>
                </div>
            </section>

            <div className="container certificate mb-35">
                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <h3>{t('CONTACTS.TAB_1.TITLE')}</h3>
                    <p>
                        {t('CONTACTS.TAB_1.DESC')}
                    </p>
                </div>
            </div>

            <div className="container certificate mb-35">

                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35" ></span>
                    <div className="clearfix"></div>
                    <h3>{t('CONTACTS.TAB_2.TITLE')}</h3>
                    <p>
                        {t('CONTACTS.TAB_2.DESC')}
                    </p>
                </div>
            </div>

            <div className="container certificate mb-35">
                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <h3>{t('CONTACTS.TAB_3.TITLE')}</h3>
                    <p>
                        {t('CONTACTS.TAB_3.DESC')}
                    </p>
                </div>
            </div>

            <div className="container certificate mb-35">

                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <h3>{t('CONTACTS.TAB_4.TITLE')}</h3>
                    <p>
                        <a href="tel:+380969982310">+38 096 998 23 10</a> (Kyivstar),<br/>
                        <a href="tel:+380978170732">+38 097 817 06 32</a> (Kyivstar),<br/>
                        <a href="tel:+380507227268">+38 050 722 72 68</a> (МТС)
                    </p>
                </div>
            </div>

            <div className="container certificate mb-35">

                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <h3>{t('CONTACTS.TAB_5.TITLE')}</h3>
                    <a href="mailto:llc.expostal@gmail.com">llc.expostal@gmail.com</a><br />
                    <a href="mailto:chepa.intersteel@gmail.com">chepa.intersteel@gmail.com</a>
                </div>
            </div>

            <div className="container certificate mb-35">

                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <h3>{t('CONTACTS.TAB_6.TITLE')}</h3>
                    <p><strong>{t('CONTACTS.TAB_6.SUB_TITLE_1')}</strong></p>
                    <p>IBAN (UA923052990000026004050550253)</p>
                    <p>{t('CONTACTS.TAB_6.SUB_TITLE_2')}</p>
                    <p>ПАО КБ "ПРИВАТБАНК", МФО 305299</p>
                    <p>ОКПО - 44540455, ИНН - 445404504686</p>
                    <br/>
                    <p><strong>{t('CONTACTS.TAB_6.SUB_TITLE_2')}</strong></p>
                    <p>JSC CB "PrivatBank"</p>
                    <p>SWIFT Code - PBANUA2X</p>
                    <p>IBAN Code - UA713052990000026005050560394 - euro</p>
                    <p>IBAN Code - UA253052990000026004050560577 - usd</p>
                    <p>VAT No.: 445404504686</p>
                    <p>Reg. No.: 44540455</p>
                    <br/>
                    EURO:<br/>
                    <div>
                        1. NAME: Comerzbank AG, Frankfurt am Main, Germany
                        A/C No.: 400886700401
                        Bank Code: COBADEFF<br/>
                        2. NAME: JP Morgan AG, Frankfurt am Main, Germany
                        A/C No.: 6231605145
                        Bank Code: CHASDEFX
                    </div>
                    <br/>
                    <div>
                        USD:<br/>
                        1. NAME: JP Morgan Chase Bank, New York, USA
                        A/C No.: 001-1-000080
                        Bank Code: CHASUS33<br/>
                        2. NAME: The Bank of New York Mellon, New York, USA
                        A/C No.: 890-0085-754
                        Bank Code: IRVT US 3N
                    </div>
                </div>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2645.9144019313762!2d35.20419251566022!3d48.45817187925039!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d95617a04a7409%3A0x78dc8700ba837e74!2z0JTQsNGH0L3QsCDQstGD0LvQuNGG0Y8sIDEwNiwg0JTQvdGW0L_RgNC-LCDQlNC90ZbQv9GA0L7Qv9C10YLRgNC-0LLRgdGM0LrQsCDQvtCx0LvQsNGB0YLRjCwgNDkwMDA!5e0!3m2!1suk!2sua!4v1662015431955!5m2!1suk!2sua"
                width="100%" height="450" allowFullScreen="" loading="lazy"></iframe>

            <br/>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4814.68834868722!2d35.1100586552354!3d48.418691340494334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dbfc598203efd9%3A0xcc3fca0d0e6ea3f0!2z0LLRg9C70LjRhtGPINCT0LDQstCw0L3RgdGM0LrQsCwgOdCyLCDQlNC90ZbQv9GA0L4sINCU0L3RltC_0YDQvtC_0LXRgtGA0L7QstGB0YzQutCwINC-0LHQu9Cw0YHRgtGMLCA0OTAwMA!5e0!3m2!1suk!2sua!4v1662015562491!5m2!1suk!2sua"
                width="100%" height="450" allowFullScreen="" loading="lazy"></iframe>

        </div>
    );
}

export default Contacts;