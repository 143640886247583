import Slider from "../components/Slider";
import {Trans, useTranslation} from "react-i18next";
const Product3 = () => {
    const { t } = useTranslation();

    return (
        <div id="content-wrapper">
            <Slider />
            <section id="titlebar">
                <div className="container">
                    <div className="eight">
                        <h2>{t('PRODUCT_3.TITLE')}</h2>
                    </div>
                </div>
            </section>
            <div className="container mb-35">
                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <p>
                        <Trans i18nKey={'PRODUCT_3.TAB_1.DESC'}>
                            <strong>Износостойкий лист</strong> - данная группа сталей представляет собой семейство
                            износостойких сталей, специально разработанных для использования там, где требуется высокая
                            износостойкость. Чаще всего они используются на жизненно важных работах по переработке и
                            транспортировке сыпучих материалов, руды и угля, в цементной промышленности, химической
                            промышленности, сельском хозяйстве и т. Д. Выбранный химический состав и термическая обработка
                            обеспечивают функциональность стали с гарантированной вязкостью при низких температурах и
                            классифицированным диапазоном твердости от 250 до 600 HB. Мы поставляем износостойкую сталь в
                            закаленном и улучшенном состоянии, в зависимости от индивидуального качества и размера. Эти
                            стали являются «фирменными изделиями», поэтому они известны пользователям под типичным
                            названиями, которые производители присвоили сами:
                        </Trans>
                    </p>
                    <table className="standard-table">
                        <tr>
                            <td style={{minWidth: '70px'}}>
                                {t('PRODUCT_3.TAB_1.TABLE.TR_1.TD_1')}
                            </td>
                            <td>{t('PRODUCT_3.TAB_1.TABLE.TR_1.TD_2')}</td>
                            <td>{t('PRODUCT_3.TAB_1.TABLE.TR_1.TD_3')}</td>
                            <td>{t('PRODUCT_3.TAB_1.TABLE.TR_1.TD_4')}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div className="container mb-35">
                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>

                    <p>
                        <Trans i18nKey={'PRODUCT_3.TAB_2.DESC'}>
                            <strong>Высокопрочный стальной прокат</strong> – это металлопродукция, которая при поставке имеет
                            предел текучести более 300 МПа, предел прочности – до 2500 МПа. Высокопрочная листовая сталь все
                            шире применяется в различных отраслях промышленности благодаря снижению металлоемкости,
                            увеличению срока службы изделий, уменьшению производственных и эксплуатационных затрат в
                            сравнении с продукцией из традиционных марок стали. Прочностные характеристики металла
                            достигаются путем использования различных систем легирования стали, специальных режимов горячей
                            прокатки (таких, как контролируемая прокатка, или нормализующая, или термомеханический процесс),
                            а также термической обработкой (закалка с отпуском, нормализация). Помимо высокой прочности,
                            материал обладает повышенной ударной вязкостью (в т.ч. при отрицательных температурах), хорошей
                            пластичностью и свариваемостью:
                        </Trans>
                    </p>
                    <table className="standard-table">
                        <tr>
                            <td>{t('PRODUCT_3.TAB_2.TABLE.TR_1.TD_1')}</td>
                            <td>{t('PRODUCT_3.TAB_2.TABLE.TR_1.TD_2')}</td>
                            <td>{t('PRODUCT_3.TAB_2.TABLE.TR_1.TD_3')}</td>
                            <td>{t('PRODUCT_3.TAB_2.TABLE.TR_1.TD_4')}</td>
                        </tr>
                    </table>
                    <p>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Product3;