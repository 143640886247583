import {useTranslation} from "react-i18next";
import Logo from '../images/logo.png';

const Header = () => {
    const { t, i18n } = useTranslation();
    const changeLanguage = (e) => {
        i18n.changeLanguage(e.target.value);
        localStorage.setItem('lang', e.target.value);
        window.location.reload();
    };
    return (
        <header id="header">
            <div className="container flex-header">

                <div className="three columns">

                    <div id="mobile-navigation">
                        <a href="/#menu" className="menu-trigger"><i className="icon-reorder"></i></a>
                    </div>
                    <div id="logo">
                        <div className="desktopshow">
                            <h1>
                                <a href="/"><img src={Logo} alt="Inter Steel" width="241" /></a>
                            </h1>
                        </div>
                        <div className="mobileshow">
                            <h1>
                                <a href="/"><img src={Logo} alt="Inter Steel" width="241" /></a>
                            </h1>
                        </div>
                    </div>

                </div>
                <div className="thirteen columns">

                    <nav id="navigation" className="menu">
                        <ul id="responsive">

                            <li><a href="/">{t('HEADER.ABOUT')}</a></li>
                            <li><a href="#">{t('HEADER.PRODUCTS.TITLE')}</a>
                                <ul>
                                    <li><a href="/product-1">{t('HEADER.PRODUCTS.PRODUCT_1')}</a></li>
                                    <li><a href="/product-2">{t('HEADER.PRODUCTS.PRODUCT_2')}</a></li>
                                    <li><a href="/product-3">{t('HEADER.PRODUCTS.PRODUCT_3')}</a></li>
                                    <li><a href="/product-4">{t('HEADER.PRODUCTS.PRODUCT_4')}</a></li>
                                    <li><a href="/product-5">{t('HEADER.PRODUCTS.PRODUCT_5')}</a></li>
                                </ul>
                            </li>
                            <li><a href="/certificate">{t('HEADER.CERTIFICATES')}</a></li>

                            <li><a href="/services
                            ">{t('HEADER.SERVICES')}</a></li>

                            <li><a href="/partners">{t('HEADER.PARTNERS')}</a></li>

                            <li><a href="/contacts">{t('HEADER.CONTACTS')}</a></li>
                            <li>
                                <select className="lang-select" value={i18n.language} onChange={changeLanguage}>
         
                                    <option value="ua">ua</option>
    
                                </select>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Header;