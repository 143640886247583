import Slider from "../components/Slider";
import {useTranslation} from "react-i18next";
const Product3 = () => {
    const { t } = useTranslation();

    return (
        <div id="content-wrapper">
            <Slider />
            <section id="titlebar">
                <div className="container">
                    <div className="eight">
                        <h2>{t('PRODUCT_5.TITLE')}</h2>
                    </div>
                </div>
            </section>

            <div className="container mb-35">

                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <p>
                        {t('PRODUCT_5.DESC')}
                    </p>
                    <p><strong>{t('PRODUCT_5.TAB_1.DESC')}</strong></p>
                    <table className="standard-table">
                        <tr>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_1.TD_1')}
                            </td>
                            <td>{t('PRODUCT_5.TAB_1.TABLE.TR_1.TD_2')}</td>
                            <td rowSpan="4" className="vm">
                                {t('PRODUCT_5.TAB_1.TABLE.TR_1.TD_3')}
                            </td>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_1.TD_4')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_2.TD_1')}
                            </td>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_2.TD_2')}
                            </td>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_2.TD_4')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_3.TD_1')}
                            </td>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_3.TD_2')}
                            </td>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_3.TD_4')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_4.TD_1')}
                            </td>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_4.TD_2')}
                            </td>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_4.TD_4')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_5.TD_1')}
                            </td>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_5.TD_2')}
                            </td>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_5.TD_3')}
                            </td>
                            <td rowSpan="4" className="vm">
                                {t('PRODUCT_5.TAB_1.TABLE.TR_5.TD_4')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_6.TD_1')}
                            </td>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_6.TD_2')}
                            </td>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_6.TD_3')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_7.TD_1')}
                            </td>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_7.TD_2')}
                            </td>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_7.TD_3')}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_8.TD_1')}
                            </td>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_8.TD_2')}
                            </td>
                            <td>
                                {t('PRODUCT_5.TAB_1.TABLE.TR_8.TD_3')}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Product3;