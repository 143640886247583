import {useTranslation, Trans} from "react-i18next";

import SliderBg from '../images/slider-bg.png'
import SlideImg1 from '../images/slider/1.png'
import SlideImg2 from '../images/slider/2.png'
import SlideImg3 from '../images/slider/3.png'
import SlideImg4 from '../images/slider/4.png'
import SlideImg5 from '../images/slider/5.png'
import SlideImg6 from '../images/slider/6.png'
import SlideImg7 from '../images/slider/7.png'
import SlideImg8 from '../images/slider/8.png'



const Slider = () => {
    const { t } = useTranslation();
    return (
        <div className="fullwidthbanner-container">
            <div className="fullwidthbanner">
                <ul>
                    <li data-fstransition="fade" data-transition="fade" data-slotamount="10" data-masterspeed="300">
                        <img src={SliderBg} alt="" />
                        <div className="desktopshow">
                            <div className="caption text sfb" data-x="0" data-y="130" data-speed="400" data-start="800" data-easing="easeOutExpo">
                                <h2>{t('SLIDER.TITLE')}</h2>
                            </div>
                            <div className="caption text sfb" data-x="1" data-y="170" data-speed="400" data-start="1000" data-easing="easeOutExpo">
                                <h3>{t('SLIDER.SUB_TITLE')}</h3>
                            </div>
                            <div className="caption text sfb" data-x="1" data-y="170" data-speed="400" data-start="1000" data-easing="easeOutExpo">
                                <h3>&nbsp;</h3>
                            </div>
                            <div className="caption text sfb" data-x="1" data-y="226" data-speed="400" data-start="1200" data-easing="easeOutExpo">
                                <p>
                                    <Trans i18nKey="SLIDER.DESC">
                                        "Единственный способ делать великие дела<br />
                                        - любить то, что Вы делаете"<br />
                                        Стив Джобс
                                    </Trans>
                                </p>
                            </div>
                        </div>

                        <div className="mobileshow">
                            <div className="caption text sfb" data-x="0" data-y="130" data-speed="400" data-start="800" data-easing="easeOutExpo">
                                <h3>ЕкспоСталь</h3>
                                <p>&nbsp;</p>
                                <h3>Искусство в стали</h3>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div className="sliderbild1">
                            <div className="caption sft" data-x="440" data-y="71" data-speed="600" data-start="1300" data-easing="easeOutExpo"><img src={SlideImg1} alt="" /></div>
                        </div>
                        <div className="caption sfb" data-x="740" data-y="71" data-speed="600" data-start="1200" data-easing="easeOutExpo"><img src={SlideImg2} alt="" /></div>
                    </li>

                    <li data-fstransition="fade" data-transition="fade" data-slotamount="10" data-masterspeed="300">
                        <img src={SliderBg} alt="" />
                        <div className="desktopshow">
                            <div className="caption text sfb" data-x="0" data-y="130" data-speed="400" data-start="800" data-easing="easeOutExpo">
                                <h2>{t('SLIDER.TITLE')}</h2>
                            </div>
                            <div className="caption text sfb" data-x="1" data-y="170" data-speed="400" data-start="1000" data-easing="easeOutExpo">
                                <h3>{t('SLIDER.SUB_TITLE')}</h3>
                            </div>
                            <div className="caption text sfb" data-x="1" data-y="170" data-speed="400" data-start="1000" data-easing="easeOutExpo">
                                <h3>&nbsp;</h3>
                            </div>
                            <div className="caption text sfb" data-x="1" data-y="226" data-speed="400" data-start="1200" data-easing="easeOutExpo">
                                <p>
                                    <Trans i18nKey="SLIDER.DESC">
                                        "Единственный способ делать великие дела<br />
                                        - любить то, что Вы делаете"<br />
                                        Стив Джобс
                                    </Trans>
                                </p>
                            </div>
                        </div>
                        <div className="mobileshow">
                            <div className="caption text sfb" data-x="0" data-y="130" data-speed="400" data-start="800" data-easing="easeOutExpo">
                                <h3>{t('SLIDER.TITLE')}</h3>
                                <p>&nbsp;</p>
                                <h3>{t('SLIDER.SUB_TITLE')}</h3>
                                <p>&nbsp;</p>
                            </div>
                        </div>

                        <div className="sliderbild1">
                            <div className="caption sft" data-x="440" data-y="71" data-speed="600" data-start="1300" data-easing="easeOutExpo"><img src={SlideImg3} alt="" /></div>
                        </div>
                        <div className="caption sfb" data-x="740" data-y="71" data-speed="600" data-start="1200" data-easing="easeOutExpo"><img src={SlideImg4} alt="" /></div>
                    </li>

                    <li data-fstransition="fade" data-transition="fade" data-slotamount="10" data-masterspeed="300">
                        <img src={SliderBg} alt="" />
                        <div className="desktopshow">
                            <div className="caption text sfb" data-x="0" data-y="130" data-speed="400" data-start="800" data-easing="easeOutExpo">
                                <h2>{t('SLIDER.TITLE')}</h2>
                            </div>
                            <div className="caption text sfb" data-x="1" data-y="170" data-speed="400" data-start="1000" data-easing="easeOutExpo">
                                <h3>{t('SLIDER.SUB_TITLE')}</h3>
                            </div>
                            <div className="caption text sfb" data-x="1" data-y="170" data-speed="400" data-start="1000" data-easing="easeOutExpo">
                                <h3>&nbsp;</h3>
                            </div>
                            <div className="caption text sfb" data-x="1" data-y="226" data-speed="400" data-start="1200" data-easing="easeOutExpo">
                                <p>
                                    <Trans i18nKey="SLIDER.DESC">
                                        "Единственный способ делать великие дела<br />
                                        - любить то, что Вы делаете"<br />
                                        Стив Джобс
                                    </Trans>
                                </p>
                            </div>
                        </div>

                        <div className="mobileshow">
                            <div className="caption text sfb" data-x="0" data-y="130" data-speed="400" data-start="800" data-easing="easeOutExpo">
                                <h3>{t('SLIDER.TITLE')}</h3>
                                <p>&nbsp;</p>
                                <h3>{t('SLIDER.SUB_TITLE')}</h3>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div className="sliderbild1">
                            <div className="caption sft" data-x="440" data-y="71" data-speed="600" data-start="1300" data-easing="easeOutExpo"><img src={SlideImg5} alt="" /></div>
                        </div>
                        <div className="caption sfb" data-x="740" data-y="71" data-speed="600" data-start="1200" data-easing="easeOutExpo"><img src={SlideImg6} alt="" /></div>
                    </li>

                    <li data-fstransition="fade" data-transition="fade" data-slotamount="10" data-masterspeed="300">
                        <img src={SliderBg} alt="" />
                        <div className="desktopshow">
                            <div className="caption text sfb" data-x="0" data-y="130" data-speed="400" data-start="800" data-easing="easeOutExpo">
                                <h2>{t('SLIDER.TITLE')}</h2>
                            </div>
                            <div className="caption text sfb" data-x="1" data-y="170" data-speed="400" data-start="1000" data-easing="easeOutExpo">
                                <h3>{t('SLIDER.SUB_TITLE')}</h3>
                            </div>
                            <div className="caption text sfb" data-x="1" data-y="170" data-speed="400" data-start="1000" data-easing="easeOutExpo">
                                <h3>&nbsp;</h3>
                            </div>
                            <div className="caption text sfb" data-x="1" data-y="226" data-speed="400" data-start="1200" data-easing="easeOutExpo">
                                <p>
                                    <Trans i18nKey="SLIDER.DESC">
                                        "Единственный способ делать великие дела<br />
                                        - любить то, что Вы делаете"<br />
                                        Стив Джобс
                                    </Trans>
                                </p>
                            </div>
                        </div>

                        <div className="mobileshow">
                            <div className="caption text sfb" data-x="0" data-y="130" data-speed="400" data-start="800" data-easing="easeOutExpo">
                                <h3>{t('SLIDER.TITLE')}</h3>
                                <p>&nbsp;</p>
                                <h3>{t('SLIDER.SUB_TITLE')}</h3>
                                <p>&nbsp;</p>
                            </div>
                        </div>

                        <div className="sliderbild1">
                            <div className="caption sft" data-x="440" data-y="71" data-speed="600" data-start="1300" data-easing="easeOutExpo"><img src={SlideImg7} alt="" /></div>
                        </div>
                        <div className="caption sfb" data-x="740" data-y="71" data-speed="600" data-start="1200" data-easing="easeOutExpo"><img src={SlideImg8} alt="" /></div>
                    </li>

                </ul>
            </div>
        </div>
    );
}

export default Slider;