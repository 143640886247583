import Slider from "../components/Slider";
import {useTranslation} from "react-i18next";
const Partners = () => {
    const { t } = useTranslation();

    return (
        <div id="content-wrapper">
            <Slider />
            <section id="titlebar">
                <div className="container">
                    <div className="eight">
                        <h2>{t('PARTNERS.TITLE')}</h2>
                    </div>
                </div>
            </section>

            <div className="container certificate mb-35">
                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <h3><a href="https://www.nzf.com.ua" target="_blank" rel="nofollow">
                        {t('PARTNERS.1')}
                    </a></h3>
                    <h3><a href="http://mgok.dp.ua/" target="_blank" rel="nofollow">
                        {t('PARTNERS.2')}
                    </a></h3>
                    <h3><a href="https://pokrovgzk.com.ua/" target="_blank" rel="nofollow">
                        {t('PARTNERS.3')}
                    </a></h3>
                    <h3><a href="http://nkmz.com/" target="_blank" rel="nofollow">
                        {t('PARTNERS.4')}
                    </a></h3>
                    <h3><a href="http://www.ugok.com.ua/ua/" target="_blank" rel="nofollow">
                        {t('PARTNERS.5')}
                    </a></h3>
                    <h3><a href="https://ukrtatnafta.com/" target="_blank" rel="nofollow">
                        {t('PARTNERS.6')}
                    </a></h3>
                </div>
            </div>
        </div>
    );
}

export default Partners;