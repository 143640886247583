import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Services from "./pages/Services";
import Contacts from "./pages/Contacts";
import Partners from "./pages/Partners";
import Certificates from "./pages/Certificates";
import Product1 from "./pages/Product1";
import Product2 from "./pages/Product2";
import Product3 from "./pages/Product3";
import Product4 from "./pages/Product4";
import Product5 from "./pages/Product5";
function App() {
  return (
      <BrowserRouter>
          <div className="App">
              <Header />
              <Switch>
                  <Route path="/" exact component={Home} />
                  <Route path="/services" exact component={Services} />

                  <Route path="/contacts" exact component={Contacts} />
                  <Route path="/partners" exact component={Partners} />
                  <Route path="/certificate" exact component={Certificates} />
                  <Route path="/product-1" exact component={Product1} />
                  <Route path="/product-2" exact component={Product2} />
                  <Route path="/product-3" exact component={Product3} />
                  <Route path="/product-4" exact component={Product4} />
                  <Route path="/product-5" exact component={Product5} />
              </Switch>
              <Footer />
          </div>
      </BrowserRouter>
  );
}

export default App;
