import Slider from "../components/Slider";
import {useTranslation, Trans} from "react-i18next";
const Product1 = () => {
    const { t } = useTranslation();

    return (
        <div id="content-wrapper">
            <Slider />
            <section id="titlebar">
                <div className="container">
                    <div className="eight">
                        <h2>{t('PRODUCT_1.TITLE')}</h2>
                    </div>
                </div>
            </section>

            <div className="container mb-35">
                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <p>
                        {t('PRODUCT_1.DESC')}
                    </p>
                    <p>
                        <strong>
                            {t('PRODUCT_1.TAB_1.TITLE')}
                        </strong>
                    </p>
                    <p>

                        <Trans i18nKey={'PRODUCT_1.TAB_1.DESC'}>
                            <strong>- Трубы водогазопроводные</strong> – имеет продольный сварной шов, благодаря значительной толщине стенки стальной трубы на ней можно нарезать внутреннюю или наружную резьбу дюймового или метрического ряда. Данная труба может применяться для использования: в газопроводах; в технических и питьевых водопроводных линиях; в бытовых, коммерческих и промышленных системах отопления; в качестве заготовки для деталей водо- и газоснабжающих коммуникаций и оборудования.
                        </Trans>
                    </p>
                    <table className="standard-table">
                        <tr>
                            <th>{t('PRODUCT_1.TAB_1.TABLE.TR_1.TH_1')}</th>
                            <th>{t('PRODUCT_1.TAB_1.TABLE.TR_1.TH_2')}</th>
                            <th>{t('PRODUCT_1.TAB_1.TABLE.TR_1.TH_3')}</th>
                            <th>{t('PRODUCT_1.TAB_1.TABLE.TR_1.TH_4')}</th>
                            <th>{t('PRODUCT_1.TAB_1.TABLE.TR_1.TH_5')}</th>
                        </tr>
                        <tr>
                            <td>{t('PRODUCT_1.TAB_1.TABLE.TR_2.TD_1')}</td>
                            <td>{t('PRODUCT_1.TAB_1.TABLE.TR_2.TD_2')}</td>
                            <td>{t('PRODUCT_1.TAB_1.TABLE.TR_2.TD_3')}</td>
                            <td>{t('PRODUCT_1.TAB_1.TABLE.TR_2.TD_4')}</td>
                            <td>{t('PRODUCT_1.TAB_1.TABLE.TR_2.TD_5')}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className="container mb-35">
                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <p>
                        <Trans i18nKey={'PRODUCT_1.TAB_2.DESC'}>
                            <strong>- Трубы бесшовные горячедеформированные и холоднодеформированные трубы</strong> – за счет отсутствия сварного шва обладают очень большой прочностью, потому используются при строительстве нефтяных и подводных трубопроводных систем, а также производстве машин.
                        </Trans>
                    </p>
                    <table className="standard-table">
                        <tr>
                            <th>{t('PRODUCT_1.TAB_2.TABLE.TR_1.TH_1')}</th>
                            <th>{t('PRODUCT_1.TAB_2.TABLE.TR_1.TH_2')}</th>
                            <th>{t('PRODUCT_1.TAB_2.TABLE.TR_1.TH_3')}</th>
                            <th>{t('PRODUCT_1.TAB_2.TABLE.TR_1.TH_4')}</th>
                        </tr>
                        <tr>
                            <td>{t('PRODUCT_1.TAB_2.TABLE.TR_2.TD_1')}</td>
                            <td>{t('PRODUCT_1.TAB_2.TABLE.TR_2.TD_2')}</td>
                            <td>{t('PRODUCT_1.TAB_2.TABLE.TR_2.TD_3')}</td>
                            <td>{t('PRODUCT_1.TAB_2.TABLE.TR_2.TD_4')}</td>
                        </tr>
                    </table>
                    <p>
                    </p>
                </div>
            </div>
            <div className="container mb-35">
                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <p>
                        <Trans i18nKey={'PRODUCT_1.TAB_3.DESC'}>
                            <strong>- Электросварные трубы прямошовные и спиралешовные</strong> - могут применяться как при строительстве трубопроводов (бытовых, промышленных), так и при возведении различных металлоконструкций.
                        </Trans>
                    </p>
                    <table className="standard-table">
                        <tr>
                            <th>{t('PRODUCT_1.TAB_3.TABLE.TR_1.TH_1')}</th>
                            <th>{t('PRODUCT_1.TAB_3.TABLE.TR_1.TH_2')}</th>
                            <th>{t('PRODUCT_1.TAB_3.TABLE.TR_1.TH_3')}</th>
                            <th>{t('PRODUCT_1.TAB_3.TABLE.TR_1.TH_4')}</th>
                        </tr>
                        <tr>
                            <td>{t('PRODUCT_1.TAB_3.TABLE.TR_2.TD_1')}</td>
                            <td>{t('PRODUCT_1.TAB_3.TABLE.TR_2.TD_2')}</td>
                            <td>{t('PRODUCT_1.TAB_3.TABLE.TR_2.TD_3')}</td>
                            <td>{t('PRODUCT_1.TAB_3.TABLE.TR_2.TD_4')}</td>
                        </tr>
                    </table>
                    <p>
                    </p>
                </div>
            </div>
            <div className="container mb-35">

                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <p>
                        <Trans i18nKey={'PRODUCT_1.TAB_4.DESC'}>
                            <strong>Профильные квадратные и прямоугольные трубы</strong> – такая труба широко используется в строительстве: от жилых домов до промышленных цехов, от ангаров до торговых павильонов, от осветительных мачт до заборов. Профильная труба за счет своей формы получила большую жесткость и устойчивость.
                        </Trans>
                    </p>
                    <table className="standard-table">
                        <tr>
                            <th>{t('PRODUCT_1.TAB_4.TABLE.TR_1.TH_1')}</th>
                            <th>{t('PRODUCT_1.TAB_4.TABLE.TR_1.TH_2')}</th>
                            <th>{t('PRODUCT_1.TAB_4.TABLE.TR_1.TH_3')}</th>
                            <th>{t('PRODUCT_1.TAB_4.TABLE.TR_1.TH_4')}</th>
                        </tr>
                        <tr>
                            <td>{t('PRODUCT_1.TAB_4.TABLE.TR_2.TD_1')}</td>
                            <td>{t('PRODUCT_1.TAB_4.TABLE.TR_2.TD_2')}</td>
                            <td>{t('PRODUCT_1.TAB_4.TABLE.TR_2.TD_3')}</td>
                            <td>{t('PRODUCT_1.TAB_4.TABLE.TR_2.TD_4')}</td>
                        </tr>
                    </table>
                    <p>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Product1;