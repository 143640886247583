import {useTranslation} from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();
    return (
        <div id="footer-bottom">
            <div className="container">
                <div className="eight columns">© Copyright 2021 by <strong>EкспоСталь</strong>. All Rights Reserved. </div>
                <div className="eight columns">
                </div>
            </div>
        </div>
    );
}

export default Footer;