import Slider from "../components/Slider";
import {Trans, useTranslation} from "react-i18next";
const Product2 = () => {
    const { t } = useTranslation();

    return (
        <div id="content-wrapper">
            <Slider />
            <section id="titlebar">
                <div className="container">

                    <div className="eight">
                        <h2>{t('PRODUCT_2.TITLE')}</h2>
                    </div>

                </div>
            </section>

            <div className="container mb-35">
                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <p>
                        {t('PRODUCT_2.DESC')}
                    </p>
                    <p><strong>{t('PRODUCT_2.TAB_1.TITLE')}</strong></p>
                    <p>
                        <Trans i18nKey={'PRODUCT_2.TAB_1.DESC'}>
                            <strong>- Конструкционные стали</strong> - строительство, мостостроение и производство
                            металлоконструкций общего назначения являются основными сферами использования толстолистовой
                            стали. В данном сегменте востребован углеродистый и низколегированный прокат, обладающий хорошей
                            свариваемостью, высокой прочностью, вязкостью и трещиностойкостью. Для отдельных применений
                            конструкционный прокат должен иметь высокую стойкость к атмосферной коррозии:
                        </Trans>
                    </p>
                    <table className="standard-table">
                        <tr>
                            <td>{t('PRODUCT_2.TAB_1.TABLE.TR_1.TD_1')}</td>
                            <td>{t('PRODUCT_2.TAB_1.TABLE.TR_1.TD_2')}</td>
                            <td>{t('PRODUCT_2.TAB_1.TABLE.TR_1.TD_3')}</td>
                            <td>{t('PRODUCT_2.TAB_1.TABLE.TR_1.TD_4')}</td>
                        </tr>
                        <tr>
                            <td>{t('PRODUCT_2.TAB_1.TABLE.TR_2.TD_1')}</td>
                            <td>{t('PRODUCT_2.TAB_1.TABLE.TR_2.TD_2')}</td>
                            <td>{t('PRODUCT_2.TAB_1.TABLE.TR_2.TD_3')}</td>
                            <td>{t('PRODUCT_2.TAB_1.TABLE.TR_2.TD_4')}</td>
                        </tr>
                        <tr>
                            <td>{t('PRODUCT_2.TAB_1.TABLE.TR_3.TD_1')}</td>
                            <td>{t('PRODUCT_2.TAB_1.TABLE.TR_3.TD_2')}</td>
                            <td>{t('PRODUCT_2.TAB_1.TABLE.TR_3.TD_3')}</td>
                            <td>{t('PRODUCT_2.TAB_1.TABLE.TR_3.TD_4')}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div className="container mb-35">

                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <p>
                        <Trans i18nKey={'PRODUCT_2.TAB_2.DESC'}>
                            <strong>- Нелегированная и легированная сталь для сосудов высокого давления</strong> -
                            толстолистовая котловая сталь применяется для производства корпусов котлов и резервуаров,
                            цистерн для перевозки сжиженных газов, нефтепродуктов и других жидких и газообразных грузов;
                            изготовление фитингов, запорной арматуры и других вспомогательных элементов для оборудования,
                            работающего под давлением:
                        </Trans>
                    </p>
                    <table className="standard-table">
                        <tr>
                            <td>{t('PRODUCT_2.TAB_2.TABLE.TR_1.TD_1')}</td>
                            <td rowSpan="2" className="vm">{t('PRODUCT_2.TAB_2.TABLE.TR_1.TD_2')}</td>
                            <td>{t('PRODUCT_2.TAB_2.TABLE.TR_1.TD_3')}</td>
                            <td>{t('PRODUCT_2.TAB_2.TABLE.TR_1.TD_4')}</td>
                        </tr>
                        <tr>
                            <td>{t('PRODUCT_2.TAB_2.TABLE.TR_2.TD_1')}</td>
                            <td>{t('PRODUCT_2.TAB_2.TABLE.TR_2.TD_3')}</td>
                            <td>{t('PRODUCT_2.TAB_2.TABLE.TR_2.TD_4')}</td>
                        </tr>
                        <tr>
                            <td>{t('PRODUCT_2.TAB_2.TABLE.TR_3.TD_1')}</td>
                            <td>{t('PRODUCT_2.TAB_2.TABLE.TR_3.TD_2')}</td>
                            <td>{t('PRODUCT_2.TAB_2.TABLE.TR_3.TD_3')}</td>
                            <td rowSpan="4" className="vm">
                                {t('PRODUCT_2.TAB_2.TABLE.TR_3.TD_4')}
                            </td>
                        </tr>
                        <tr>
                            <td>{t('PRODUCT_2.TAB_2.TABLE.TR_4.TD_1')}</td>
                            <td>{t('PRODUCT_2.TAB_2.TABLE.TR_4.TD_2')}</td>
                            <td>{t('PRODUCT_2.TAB_2.TABLE.TR_4.TD_3')}</td>
                        </tr>
                    </table>
                    <p>
                    </p>
                </div>
            </div>

            <div className="container mb-35">
                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <p>
                        <Trans i18nKey={'PRODUCT_2.TAB_3.DESC'}>
                            <strong>- Цементационная сталь</strong> - сталь марки 16MnCr5 - это сталь для поверхностного
                            упрочнения, широко используется при цементации, нитроцементации, азотировании и других видах
                            поверхностной химико-термической обработки. Из стали изготавливают валы, оси, шестерни, зубчатые
                            колеса, болты, рычаги и другие транспортные и машиностроительные составные части. Данная марка
                            стали имеет удовлетворительную свариваемость, при этом следует учитывать возможность
                            возникновения трещин по сварному шву:
                        </Trans>
                    </p>
                    <table className="standard-table">
                        <tr>
                            <td>{t('PRODUCT_2.TAB_3.TABLE.TR_1.TD_1')}</td>
                            <td>{t('PRODUCT_2.TAB_3.TABLE.TR_1.TD_2')}</td>
                            <td>{t('PRODUCT_2.TAB_3.TABLE.TR_1.TD_3')}</td>
                            <td>{t('PRODUCT_2.TAB_3.TABLE.TR_1.TD_4')}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div className="container mb-35">
                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <p>
                        <Trans i18nKey={'PRODUCT_2.TAB_4.DESC'}>
                            <strong>- Толстолистовая судовая сталь</strong> - используется при изготовлении и ремонте
                            корпусов морских судов (днище, палуба, перекрытия, переборки), а также элементов морских
                            платформ и сооружений. Основные требования – высокая прочность, низкотемпературная вязкость,
                            коррозионная стойкость, свариваемость (низкий углеродный эквивалент). В последнее время все
                            больше судостроительных предприятий с целью снижения металлоемкости в своих проектах используют
                            стальной прокат повышенной прочности после термомеханической прокатки или закалки с отпуском.
                            Для уменьшения объемов сварочных работ верфи применяют судостроительную сталь в листах больших
                            размеров – шириной от 3 до 4 метров, длиной до 20 метров:
                        </Trans>

                    </p>
                    <table className="standard-table">
                        <tr>
                            <td>{t('PRODUCT_2.TAB_4.TABLE.TR_1.TD_1')}</td>
                            <td>{t('PRODUCT_2.TAB_4.TABLE.TR_1.TD_2')}</td>
                            <td>{t('PRODUCT_2.TAB_4.TABLE.TR_1.TD_3')}</td>
                            <td>{t('PRODUCT_2.TAB_4.TABLE.TR_1.TD_4')}</td>
                        </tr>
                        <tr>
                            <td>{t('PRODUCT_2.TAB_4.TABLE.TR_2.TD_1')}</td>
                            <td>{t('PRODUCT_2.TAB_4.TABLE.TR_2.TD_2')}</td>
                            <td>{t('PRODUCT_2.TAB_4.TABLE.TR_2.TD_3')}</td>
                            <td>{t('PRODUCT_2.TAB_4.TABLE.TR_2.TD_4')}</td>
                        </tr>
                        <tr>
                            <td>{t('PRODUCT_2.TAB_4.TABLE.TR_3.TD_1')}</td>
                            <td>{t('PRODUCT_2.TAB_4.TABLE.TR_3.TD_2')}</td>
                            <td>{t('PRODUCT_2.TAB_4.TABLE.TR_3.TD_3')}</td>
                            <td>{t('PRODUCT_2.TAB_4.TABLE.TR_3.TD_4')}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Product2;