import Slider from "../components/Slider";
import {useTranslation} from "react-i18next";
const Certificates = () => {
    const { t } = useTranslation();
    return (
        <div id="content-wrapper">
            <Slider />
            <section id="titlebar">
                <div className="container">
                    <div className="eight">
                        <h2>{t('CERTIFICATE.TITLE')}</h2>
                    </div>
                </div>
            </section>

            <div className="container certificate mb-35">
                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <h3>{t('CERTIFICATE.TAB_1.TITLE')}</h3>
                    <br />
                        <p>
                            <a target="_blank" href="/certificates/section-1/1.pdf">
                                {t('CERTIFICATE.TAB_1.1')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-1/2.pdf">
                                {t('CERTIFICATE.TAB_1.2')}
                            </a>
                        </p>
                </div>

            </div>
            <div className="container certificate mb-35">
                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <h3>{t('CERTIFICATE.TAB_2.TITLE')}</h3>
                    <br />
                        <p>
                            <a target="_blank" href="/certificates/section-2/1.pdf">
                                {t('CERTIFICATE.TAB_2.1')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-2/2.pdf">
                                {t('CERTIFICATE.TAB_2.2')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-2/3.pdf">
                                {t('CERTIFICATE.TAB_2.3')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-2/4.pdf">
                                {t('CERTIFICATE.TAB_2.4')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-2/5.pdf">
                                {t('CERTIFICATE.TAB_2.5')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-2/6.pdf">
                                {t('CERTIFICATE.TAB_2.6')}
                            </a>
                        </p>
                </div>
            </div>
            <div className="container certificate mb-35">
                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <h3>{t('CERTIFICATE.TAB_3.TITLE')}</h3>
                    <br />
                        <p>
                            <a target="_blank" href="/certificates/section-3/1.pdf">
                                {t('CERTIFICATE.TAB_3.1')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-3/2.pdf">
                                {t('CERTIFICATE.TAB_3.2')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-3/3.pdf">
                                {t('CERTIFICATE.TAB_3.3')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-3/4.pdf">
                                {t('CERTIFICATE.TAB_3.4')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-3/5.pdf">
                                {t('CERTIFICATE.TAB_3.5')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-3/6.pdf">
                                {t('CERTIFICATE.TAB_3.6')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-3/7.pdf">
                                {t('CERTIFICATE.TAB_3.7')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-3/8.pdf">
                                {t('CERTIFICATE.TAB_3.8')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-3/9.pdf">
                                {t('CERTIFICATE.TAB_3.9')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-3/10.pdf">
                                {t('CERTIFICATE.TAB_3.10')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-3/11.pdf">
                                {t('CERTIFICATE.TAB_3.11')}
                            </a>
                        </p>
                </div>
            </div>
            <div className="container certificate mb-35">

                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <h3>{t('CERTIFICATE.TAB_4.TITLE')}</h3>
                    <br />
                        <p>
                            <a target="_blank" href="/certificates/section-4/1.pdf">
                                {t('CERTIFICATE.TAB_4.1')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-4/2.pdf">
                                {t('CERTIFICATE.TAB_4.2')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-4/3.pdf">
                                {t('CERTIFICATE.TAB_4.3')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-4/4.pdf">
                                {t('CERTIFICATE.TAB_4.4')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-4/5.pdf">
                                {t('CERTIFICATE.TAB_4.5')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-4/6.pdf">
                                {t('CERTIFICATE.TAB_4.6')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-4/7.pdf">
                                {t('CERTIFICATE.TAB_4.7')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-4/8.pdf">
                                {t('CERTIFICATE.TAB_4.8')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-4/9.pdf">
                                {t('CERTIFICATE.TAB_4.9')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-4/10.pdf">
                                {t('CERTIFICATE.TAB_4.10')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-4/11.pdf">
                                {t('CERTIFICATE.TAB_4.11')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-4/12.pdf">
                                {t('CERTIFICATE.TAB_4.12')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-4/12.pdf">
                                {t('CERTIFICATE.TAB_4.13')}
                            </a>
                        </p>
                </div>
            </div>
            <div className="container certificate mb-35">

                <div className="column">
                    <h3 className="headline"></h3>
                    <span className="line mb-35"></span>
                    <div className="clearfix"></div>
                    <h3>{t('CERTIFICATE.TAB_5.TITLE')}</h3>
                    <br />
                        <p>
                            <a target="_blank" href="/certificates/section-5/1.pdf">
                                {t('CERTIFICATE.TAB_5.1')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-5/2.pdf">
                                {t('CERTIFICATE.TAB_5.2')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-5/3.pdf">
                                {t('CERTIFICATE.TAB_5.3')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-5/4.pdf">
                                {t('CERTIFICATE.TAB_5.4')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-5/5.pdf">
                                {t('CERTIFICATE.TAB_5.5')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-5/6.pdf">
                                {t('CERTIFICATE.TAB_5.6')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-5/7.pdf">
                                {t('CERTIFICATE.TAB_5.7')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-5/8.pdf">
                                {t('CERTIFICATE.TAB_5.8')}
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="/certificates/section-5/9.pdf">
                                {t('CERTIFICATE.TAB_5.9')}
                            </a>
                        </p>
                </div>
            </div>
        </div>
    );
}

export default Certificates;