import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import en from './locales/en/index.json';
import ru from './locales/ru/index.json';
import ua from './locales/ua/index.json';


export default i18n
    .use(initReactI18next)
    .init({
        lng: localStorage.getItem('lang'),
        fallbackLng: 'ua',
        resources: {
            // ru: { translation: ru },
            // en: { translation: en },
            ua: { translation: ua },
        }
    });